export enum Gender {
  Man = '0',
  NonBinary = '4',
  NotListed = '6',
  PreferToHide = '7',
  TransgenderMan = '2',
  TransgenderWoman = '3',
  TwoSpirit = '5',
  Unknown = '8',
  Woman = '1'
}

export enum SexAtBirth {
  Female = '1',
  Male = '0',
  Other = '2',
  Unknown = '3'
}

export enum WMDeviceFlow {
  WMDevices = 'wmDevices',
  WMScales = 'wmScales'
}

/* Notifications settings */
export enum NotificationTypes {
  BrowserNotification = 'browserNotification',
  EmailNotification = 'emailNotification',
  TextNotification = 'textNotification'
}

export enum NotificationNames {
  AppointmentChanges = 'appointmentChanges',
  AppointmentReminder = 'appointmentReminder',
  Messages = 'messages',
  newOffers = 'newOffers'
}

export enum PathName {
  AccountInformation = '/my-account/account-information',
  AccountSettings = '/account-settings',
  AddMeasurement = '/add-measurement',
  Appointment = '/appointment',
  AppointmentRequired = '/wm-appointment-required',
  Appointments = '/appointments',
  Articles = '/articles',
  BillingDetails = '/my-account/billing-details',
  Call = '/call',
  ChangePassword = '/my-account/change-password',
  ChangePlan = '/change-plan',
  Checkout = '/checkout',
  CompleteAccount = '/complete-account',
  ConfirmAccount = '/confirm-account',
  CreateAppointment = '/appointments/create-appointment',
  CreateFreeAccount = '/create-free-account',
  CreateNewPassword = '/create-new-password',
  CreateNewRequest = '/messages/create-new-request',
  Dashboard = '/',
  DeviceStatus = '/device-status',
  Documents = '/my-chart/documents',
  ExternalMembership = 'https://www.lifemd.com/membership',
  ExternalSignUp = 'https://lifemd.com/join',
  ForgotPassword = '/forgot-password',
  HealthProfile = '/my-chart/health-profile',
  HowToUseDevices = '/how-to-use-devices',
  IntakeForm = '/intake-form',
  Labs = '/my-chart/labs',
  Login = '/login',
  MedicalRecords = '/my-chart/medical-records',
  Messages = '/messages',
  MigrateToPlan = '/migrate-to-plan',
  MigrateToWM = '/migrate-to-wm',
  MissingDataWMDevices = '/missing-data',
  MyAccount = '/my-account',
  MyChart = '/my-chart',
  MyProvider = '/my-chart/my-provider',
  NotFound = '/not-found',
  NotificationsSettings = '/my-account/notifications-settings',
  NutritionMif = '/mif-form',
  PaymentMethods = '/my-account/payment-methods',
  PostOnboarding = '/post-onboarding',
  PrescriptionStatus = '/prescriptions',
  Prescriptions = '/my-chart/prescriptions',
  ProductionHomePage = 'https://lifemd.com',
  SignUp = '/signup',
  Support = '/support',
  SymptomChecker = '/symptom-checker',
  SymptomCheckerAuth = '/health-checker',
  UnsupportedAge = '/unsupported-age',
  UpgradeAccount = '/upgrade-account',
  UpgradePlan = '/upgrade-plan',
  UpgradeToAnnualPlan = '/appointments/upgrade-to-annual',
  VerifyEmailResult = '/verify/email',
  VerifyProfile = 'verify-profile',
  WMArticle = '/weight-management/article',
  WeightManagement = '/weight-management',
  WeightManagementTabs = '/weight-management-tabs',
  ZoomCall = '/zoom-call'
}

export enum PlanTypes {
  ConciergeCare = 'cc',
  FlexCare = 'fc',
  LifeMDMembership = 'p',
  NotSelected = 'n',
  TotalCare = 'tc',
  UnlimitedPlan = 'u',
  UrgentCare = 'uc',
  WeightManagement = 'w'
}

export enum FlowTypes {
  AcipHexBFlow = 'aciphex-b',
  AcipHexFlow = 'aciphex',
  AvodartBFlow = 'avodart-b',
  AvodartFlow = 'avodart',
  BasicExtendedDynamicFlow = 'b1xc',
  BasicExtendedFlow = 'b1x',
  BasicExtendedNewPlansFlow = 'b1n',
  BasicFlow = 'b1',
  BasicFlowInfermedica = 'b1i',
  BasicFlowText = 'b1b',
  HRTClubFlow = 'hrt',
  HealthWarehouseFlow = 'hw',
  HumatinBFlow = 'humatin-b',
  HumatinFlow = 'humatin',
  InsuranceFlow = 'i1',
  InsuranceFlowAlt = 'r1',
  LovazaBFlow = 'lovaza-b',
  LovazaFlow = 'lovaza',
  MixedFlow = 'b3-hidden',
  MixedFlowWithRequiredPopup = 'b3c-hidden',
  QueueFlow = 'q1',
  QueueFlowWithNewPlans = 'q2',
  QueueFlowWithNewPlansAlt = 'q2b',
  ShortFlow = 'b2-hidden',
  ShortFlowWithOptionalPopup = 'b2c-hidden',
  TripleTherapy = 'tt',
  WeightManagementFlow = 'wm1',
  WeightManagementFlowAsync = 'wma',
  WeightManagementFlowExtended = 'wm2',
  WeightManagementFlowInsurance = 'wmi',
  WeightManagementFlowInsuranceMedications = 'wmbc',
  WeightManagementFlowOptavia = 'wm-optavia',
  WeightManagementSpecific = 'wmspec'
}

export enum SignUpSteps {
  Agreement = 'agreement',
  Allergies = 'allergies',
  AppointmentCategory = 'appointment-category',
  AppointmentConfirmation = 'appointment-confirmation',
  AppointmentDetails = 'appointment-details',
  CheckPrescriptionCoverage = 'wm-check-prescription-coverage',
  ChooseProvider = 'choose-provider',
  ChronicDiseases = 'chronic-diseases',
  Confirmation = 'confirmation',
  CreateNewUser = 'create-new-user',
  HealthConditions = 'health-conditions',
  InitialInfo = 'initial-info',
  Insurance = 'insurance',
  InsuranceType = 'wm-insurance',
  Interview = 'interview',
  Intro = 'intro',
  MarketingCompare = 'compare-to-others',
  MarketingLowerPrice = 'lower-price',
  MarketingMoneyBackGuarantee = 'money-back-guarantee',
  MarketingRatedNumberOne = 'rated-number-one-for-glp-1',
  MarketingRegularMeetings = 'regular-meetings',
  MarketingWorkingOnApproval = 'working-on-approval',
  Medications = 'medications',
  MifSummary = 'mif-summary',
  NoticeForCalifornia = 'notice-for-california-patients',
  Payment = 'payment',
  PaymentOptions = 'payment-options',
  Pharmacy = 'pharmacy',
  PickVideoOrMessageAppointment = 'pick-appointment-type',
  PlanSelect = 'plan-select',
  PreConfirmation = 'pre-confirmation',
  PreInsurance = 'check-insurance',
  PreSymptomChecker = 'pre-symptom-checker',
  PreferredMedication = 'preferred-medication',
  Pregnancy = 'wm-pregnancy',
  SCRegions = 'regions',
  SCResults = 'results',
  ShouldUseInsurance = 'should-use-insurance',
  SocialHistory = 'social-history',
  State = 'state',
  Summary = 'summary',
  SwitchToApp = 'switch-to-app',
  SymptomsSelector = 'symptoms-select',
  TwoOptionsFinish = 'finish-signup',
  UploadBodyPhoto = 'upload-body-photo',
  VerifyIdentity = 'verify-identity',
  WMAddress = 'wm-address',
  WMAge = 'wm-age',
  WMBasicInfo = 'wm-basic-info',
  WMBloodPressure = 'wm-blood-pressure',
  WMBupropion = 'wm-bupropion',
  WMCheckout = 'wm-checkout',
  WMCompleteOnboarding = 'wm-complete-onboarding',
  WMCongrats = 'wm-congrats',
  WMDOB = 'wm-dob',
  WMDiabetesQuestion = 'wm-diabetes-question',
  WMGallbladderQuestion = 'wm-gallbladder-question',
  WMGlaucoma = 'wm-glaucoma',
  WMHeightAndWeight = 'wm-height-and-weight',
  WMKetogenicDiet = 'wm-ketogenic-diet',
  WMKidneyStones = 'wm-kidney-stones',
  WMKnowledgeAboutGLP1 = 'wm-knowledge-about-glp-1',
  WMLabWork = 'wm-lab-work',
  WMLacticAcidosis = 'wm-lactic-acidosis',
  WMLiverOrKidneyImpairment = 'wm-liver-or-kidney-impairment',
  WMMAOInhibitors = 'wm-mao-inhibitors',
  WMMedicalConditions = 'wm-medical-conditions',
  WMNewTargetWeight = 'wm-new-target-weight',
  WMPancreatitisQuestion = 'wm-pancreatitis-question',
  WMPhoneNumber = 'wm-phone-number',
  WMPregnantTryingToBecomePregnant = 'wm-pregnant-trying-to-become-pregnant',
  WMPriorSeizures = 'wm-prior-seizures',
  WMScheduleAppointment = 'wm-schedule-appointment',
  WMSetPassword = 'wm-set-password',
  WMSexAtBirth = 'wm-sex-at-birth',
  WMSideEffectsWithMetforminBupropion = 'wm-side-effects-with-metformin-bupropion',
  WMTTResults = 'wm-tt-results',
  WMThyroidNodulesQuestion = 'wm-thyroid-nodules-question',
  WMUserHealthState = 'wm-user-health-state',
  WMWeightLoseAim = 'wm-weight-lose-aim',
  WMWeightLoseWithoutUncertainty = 'wm-weight-lose-without-uncertainty',
  WMWhatNext = 'wm-what-next',
  WMWhyTT = 'wm-why-triple-therapy',
  WMYouAreInGoodHands = 'wm-you-are-in-good-hands',
  WithOrWithoutInsurance = 'using-insurance',
  WomensHealth = 'womens-health',
  YourWeightLossProgram = 'your-weight-loss-program'
}

export enum SymptomCheckerSteps {
  Interview = 'interview',
  Intro1 = 'intro-1',
  Intro2 = 'intro-2',
  Patient1 = 'patient-1',
  Patient2 = 'patient-2',
  Patient3 = 'patient-3',
  Regions = 'regions',
  Results = 'results',
  Symptoms1 = 'symptoms-1',
  Symptoms2 = 'symptoms-2'
}

export enum UserStatuses {
  ACTIVE = 'active',
  DELETED = 'deleted',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  PROSPECT = 'prospect'
}

export enum FeatureFlag {
  AnnualPlanUpgradePage = 'annual-plan-upgrade-page-feature',
  AsyncSignup = 'async-signup',
  BillingDetails = 'billing-details-feature',
  BillingHistory = 'billing-history-feature',
  DarkMode = 'dark-mode',
  DocumentsPage = 'documents-page-feature',
  ExtendedUpgradeFlow = 'extended-migration-to-wm',
  GLPPrescription = 'glp-prescription',
  HealthMetrics = 'health-metrics-feature',
  HealthProfilePage = 'health-profile-feature',
  MedicalIntakeForm = 'medical-intake-form-feature',
  MedicalRecords = 'medical-records-feature',
  MemberPricing = 'member-pricing-feature',
  OnboardingWMScales = 'onboarding-wm-scales',
  Paypal = 'paypal-feature',
  PharmacyTab = 'pharmacy-tab-feature',
  PrescriptionDiscountCard = 'prescription-discount-card-feature',
  Reports = 'reports-feature',
  StateMismatch = 'state-mismatch',
  SuggestedAddress = 'suggested-address',
  WeightChartDetails = 'weight-chart-details-feature'
}

export enum PlanCodes {
  AnnualMembership = 'lifemd-annual-membership',
  Appointment = 'appointment-30',
  ConciergeMembership = 'concierge-care',
  FlexCare = 'limited-access',
  InitialAppointment = 'initial-appointment',
  LifeMDMembership = 'membership',
  TotalCareMembership = 'total-care',
  UnlimitedMembership = 'unlimited-membership',
  UrgentCare = 'urgent-care',
  WeightManagementMembership = 'weight-management'
}

export enum HealthProfileNavItem {
  MedicalIntakeForm = 'Medical intake form',
  HealthMetrics = 'Health metrics',
  HealthConditions = 'Health conditions',
  SocialHistory = 'Social history',
  ChronicDiseases = 'Chronic diseases',
  Medications = 'Medications',
  Allergies = 'Allergies',
  WomenHealth = `Women's health`
}

export enum PageTitle {
  AccountInformation = 'Account information',
  Appointments = 'Appointments',
  Back = 'Back',
  BillingCard = 'Billing & card',
  ChangePassword = 'Change password',
  ChoosePharmacy = 'Choose pharmacy',
  ChoosePlan = 'Choose plan',
  ConfirmPlanChange = 'Confirm plan change',
  CreateNewRequest = 'Create new request',
  Dashboard = 'Dashboard',
  Documents = 'Documents',
  HealthProfile = 'Health profile',
  LabsImaging = 'Labs & Imaging',
  MedicalRecords = 'Medical records',
  Messages = 'Messages',
  MyAccount = 'My account',
  MyAppointments = 'My appointments',
  MyChart = 'My chart',
  MyProvider = 'My provider',
  Notifications = 'Notifications',
  PaymentMethod = 'payment method',
  Prescriptions = 'Prescriptions',
  ScheduleAnAppointment = 'Schedule an appointment',
  Support = 'Support',
  SymptomChecker = 'Symptom checker',
  UpgradePlan = 'Upgrade plan',
  WMArticle = 'Guide',
  WeightManagement = 'Weight Management'
}

export enum DateFormat {
  D = 'D', // 1
  MMM = 'MMM', // Jan
  MMMM_D = 'MMMM D', // January 1
  MMMM_DD_YYYY = 'MMMM DD, YYYY', // May 25, 2024
  MMMM_D_h_mma_z = 'MMMM D, h:mm a z', // January 1, 1:00 am EST
  MMMM_YYYY = 'MMMM YYYY', // January 2018
  MMM_D = 'MMM D', // Jan 1
  MMM_DD = 'MMM DD', // Jan 01
  MMM_DD_YYYY = 'MMM DD, YYYY', // Jan 1 · 1:00 am EST
  MMM_D_YYYY_h_mma_z = 'MMM D · YYYY [at] h:mm a', // Jan 01 · 2018 at 1:00 am
  MMM_D_h_mma = 'MMM D · h:mma', // Jan 1 · 1:00am
  MMM_D_h_mma_z = 'MMM D · h:mm a z',
  MMM_YY = "MMM 'YY", // May '24
  MM_DD = 'MM/DD', // 01/01
  MM_DD_YYYY = 'MM/DD/YYYY', // 01/01/2018
  /** Use this format for BE requests */
  YYYY_MM_DD = 'YYYY-MM-DD', // 2023-06-06
  ddd = 'ddd', // Sun
  dddd = 'dddd', // Sunday
  dddd_MMMM_D_h_mm_A = 'dddd, MMMM D · h:mm A', // Sunday, January 1 · 1:00 AM
  dddd_MMMM_D_h_mma_z = 'dddd, MMMM D · h:mm a z', // Sunday, January 1 · 1:00am EST
  h_mm_a = 'h:mm a', // 1:00 am
  h_mma_z = 'h:mm a z', // 1:00 am EST
  hh_mma = '(hh:mm a)', // (01:00 am)
  hh_mma_z = 'hh:mm a z', // 01:00 am EST
  z = 'z' // EST
}

export enum ScreenSizes {
  DESKTOP = 'xl',
  DESKTOP_LG = '2xl',
  MOBILE = 'sm',
  MOBILE_SM = 'xs',
  TABLET = 'md',
  TABLET_LG = 'lg'
}

export enum WeekdaysShort {
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT'
}

export enum WebViewPaths {
  BillingPage = 'billing',
  CompleteAccount = 'complete-account',
  CreateAppointment = 'appMobile',
  HealthProfile = 'health-profile',
  IntakeForm = 'intake-form',
  Prescriptions = 'prescriptions',
  SymptomChecker = 'symptom-checker',
  UpgradeToWM = 'upgrade-to-wm'
}

export enum InternalLinkAction {
  AskToUpgradeToTheWM = 'ask-to-upgrade-to-the-wm'
}

export enum DeepLinkAction {
  DiscountCard = 'discount-card',
  FrontDeskChannel = 'front-desk-channel',
  IntakeForm = 'intake-form',
  Labs = 'labs',
  MessageChannel = 'message-channel',
  PlanRecommendation = 'plan-recommendation',
  SymptomChecker = 'symptom-checker',
  UpdateCreditCard = 'update-credit-card',
  WMAddWeight = 'wm-add-weight',
  WMAppointmentRequired = 'wm-appointment-required',
  WMDevicesNewMeasurements = 'wm-devices-new-measurements',
  WMDevicesShipment = 'wm-devices-shipment',
  WMGuide = 'wm-guide',
  WMSurvey = 'wm-survey',
  WaitingRoom = 'waiting-room'
}

export enum CompleteFreemiumSteps {
  AppointmentDetails = 'appointment-details',
  AppointmentFiles = 'appointment-files',
  BookAppointment = 'book-appointment',
  Checkout = 'checkout',
  ConfirmedAppointment = 'confirmed-appointment',
  CreateUser = 'create-user',
  IntakeForm = 'intake-form',
  Intro = 'intro',
  QuickCheck = 'quick-check',
  SelectPlan = 'select-plan',
  Symptoms = 'symptoms'
}

export enum ReviewProblemType {
  MyProvider = 'my-provider',
  UsingApp = 'using-app'
}

export enum ReviewOrigin {
  Appointment = 'appointment',
  Provider = 'provider'
}

export enum ShippingStatuses {
  // ASH statuses
  ASH_KIT_ACCESSIONED = 'ASH_KIT_ACCESSIONED', // kit is received by the lab
  ASH_KIT_DELIVERED_TO_PATIENT = 'ASH_KIT_DELIVERED_TO_PATIENT',
  ASH_KIT_FULFILLMENT_CANCELED = 'ASH_KIT_FULFILLMENT_CANCELED', // Handle same as rejected
  ASH_KIT_IN_TRANSIT_TO_LAB = 'ASH_KIT_IN_TRANSIT_TO_LAB',
  ASH_KIT_ISSUE = 'ASH_KIT_ISSUE', // Handle same as rejected
  ASH_KIT_REJECTED = 'ASH_KIT_REJECTED',
  ASH_ORDER_FULFILLED = 'ASH_ORDER_FULFILLED', // order is on the way to patient
  ASH_RESULTS_READY = 'ASH_RESULTS_READY',
  ASH_TEST_SAMPLES_RECEIVED = 'ASH_TEST_SAMPLES_RECEIVED',
  // END of ASH statuses
  Blocked = 'Blocked',
  Delivered = 'Delivered',
  Exception = 'Exception',
  InProgress = 'In Progress',
  Placed = 'Placed',
  Processing = 'Processing',
  Shipped = 'Shipped'
}

export enum EmailStatus {
  LEGACY = 'legacy',
  PENDING = 'pending',
  UNVERIFIED = 'unverified',
  VERIFIED = 'verified'
}

// export enum OrderStatuses {
//   CANCELED = 'Canceled',
//   COMPLETED = 'Completed',
//   IN_PROGRESS = 'In Progress',
//   MISSING_INFO = 'Missing Info',
//   NEW = 'New',
//   PENDING = 'Pending',
// }

export enum MessageEvent {
  APPOINTMENT_CANCELLED = 'appointmentCancelled',
  APPOINTMENT_COMPLETE = 'appointmentComplete',
  APPOINTMENT_CREATED = 'appointmentCreated',
  APPOINTMENT_MISSED = 'appointmentMissed'
}
export enum MIFTabs {
  AllAnswers = 'All answers',
  WarningsOnly = 'Warnings only'
}

export enum TaskStatuses {
  ACTIVE = 'Active',
  CANCELLED = 'Cancelled',
  COMPLETED = 'Completed',
  DELETED = 'Deleted',
  NEW = 'New',
  OPEN = 'Open',
  URGENT = 'URGENT'
}

export enum MessageTab {
  CARE_TEAM = 'care-team',
  FRONT_DESK = 'front-desk'
}
