import { useLocation, useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';

import { useGetFrontDeskTypesQuery, useGetMembershipPlansQuery } from 'services/lookup/lookup';

import { selectLookup, selectUser } from 'store';

import AdvantageItem from 'features/AdvantageItem';
import { BaseModalProps } from 'modals/modal.types';

import { DEFAULT_FRONT_DESK_TYPE_FOR_WM_UPGRADE } from 'constants/defaults';
import { BACK_TO_WM_PRICE_POINTS } from 'constants/onboarding';
import { useAppSelector } from 'hooks';
import { FeatureFlag, PathName, PlanCodes } from 'utils/enums';

import WmGraph from 'assets/images/wm/example-wl-graph.svg?react';

type Props = BaseModalProps & {
  alertContent?: JSX.Element;
  declineButtonText?: string;
  hideAdditionalPopup?: boolean;
  onContinueWithNoUpgrade?: () => void;
  onReopen?: () => void;
  upgradeFlow: 'messages' | 'upgrade-plan';
};

const items = [
  'Lose 15% of your body weight within a year*',
  'Clinically proven to help lose weight',
  'Optimize results with metabolic testing',
  'Curbs hunger to keep you feeling fuller'
];

const AskToUpgradeToTheWM: React.FC<Props> = ({
  onClose,
  isOpen,
  onReopen,
  onContinueWithNoUpgrade,
  upgradeFlow,
  hideAdditionalPopup = false,
  alertContent,
  declineButtonText = 'Continue without Weight Management'
}) => {
  const { hadOrHasWeightManagement } = useAppSelector(selectUser);
  const { frontDeskTypes, membershipPlans } = useAppSelector(selectLookup);
  useGetMembershipPlansQuery(undefined, { skip: membershipPlans.length > 0 });
  useGetFrontDeskTypesQuery(undefined, { skip: frontDeskTypes.length > 0 });

  const isEnabledExtendedFlow = useFlag(FeatureFlag.ExtendedUpgradeFlow);
  const [innerPopup, setInnerPopup] = useToggle(false);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const handleUpgradePlan = () => {
    const WMPlan = membershipPlans.find(
      (plan) => plan.planCode === PlanCodes.WeightManagementMembership
    );
    const ppOptionIDs =
      WMPlan?.pricePoints
        .filter((pp) => BACK_TO_WM_PRICE_POINTS.includes(pp.planPricePointId))
        .map((pp) => pp.planPricePointId)
        .join(',') || '';

    navigate({
      pathname: PathName.UpgradePlan,
      search: new URLSearchParams({
        ppOptionIDs,
        redirect: pathname + search
      }).toString()
    });
  };

  const handleNavigateToMessages = () => {
    const WM_ID = frontDeskTypes.find(
      (el) => el.requestType === DEFAULT_FRONT_DESK_TYPE_FOR_WM_UPGRADE
    )?._id;
    navigate({
      pathname: PathName.CreateNewRequest,
      search: `dc=${WM_ID ?? ''}&dm=I’d like to upgrade my plan to include the Weight Management program.`
    });
  };

  const handleContinue = () => {
    if (upgradeFlow === 'messages') {
      handleNavigateToMessages();
    } else if (isEnabledExtendedFlow && !hadOrHasWeightManagement) {
      navigate({ pathname: PathName.MigrateToWM });
    } else {
      handleUpgradePlan();
    }
    onClose();
  };

  const acceptButtonText =
    upgradeFlow === 'messages' ? 'Message staff for upgrade' : 'Upgrade plan';

  return (
    <>
      <Common.Modal close={onClose} isOpen={isOpen} size="lg" persist>
        <div className="flex flex-col gap-6 md:gap-8">
          {!!alertContent && (
            <Common.Alert type="info" colorableBackground>
              {alertContent}
            </Common.Alert>
          )}
          <h2 className="text-mLg font-bold md:text-2xl">
            Lose Weight With GLP-1 Medication Prescribed Online & Delivered
          </h2>
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-2">
              {items.map((item) => (
                <AdvantageItem advantage={item} className="text-mSm md:text-base" key={item} />
              ))}
            </div>
            <WmGraph />
          </div>
          <div className="flex flex-col gap-3 max-md:order-last">
            <Common.Button
              className="!w-full"
              color="blue"
              fullWidthOnMobile
              onClick={handleContinue}
            >
              {acceptButtonText}
            </Common.Button>
            <Common.Button
              className="!w-full"
              color="white-alt"
              fullWidthOnMobile
              onClick={() => {
                !hideAdditionalPopup && setInnerPopup(true);
                onClose();
              }}
            >
              {declineButtonText}
            </Common.Button>
          </div>
          <p className="text-mXs md:text-xs">
            *Patients taking semaglutide (the active ingredient in Ozempic & Wegovy) for 68 weeks
            lost up to 15% of their total body weight.
          </p>
        </div>
      </Common.Modal>
      <Common.Modal close={setInnerPopup} isOpen={innerPopup} size="lg" persist>
        <div className="flex flex-col gap-6">
          <div>
            <h2 className="text-mLg font-bold md:text-xl">Continue without Weight Management?</h2>
          </div>
          <Common.Alert type="warning" colorableBackground>
            <span className="text-base">
              <b>Please note:</b> We are unable to prescribe GLP-1 prescriptions if you choose to
              continue on this plan.
            </span>
          </Common.Alert>
          <div className="flex flex-col-reverse gap-3 md:flex-row md:justify-end md:gap-2">
            <Common.Button
              className="md:!w-1/2"
              color="white-alt"
              fullWidthOnMobile
              onClick={() => {
                setInnerPopup(false);
                onReopen?.();
              }}
            >
              Back
            </Common.Button>
            <Common.Button
              className="md:!w-1/2"
              color="blue"
              fullWidthOnMobile
              onClick={() => {
                setInnerPopup(false);
                onContinueWithNoUpgrade?.();
              }}
            >
              Continue
            </Common.Button>
          </div>
        </div>
      </Common.Modal>
    </>
  );
};

export default AskToUpgradeToTheWM;
