import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

// import { useLottie } from 'lottie-react';
import { useGenerateDynamicLinkMutation, useGetShortTermTokenMutation } from 'services/auth/auth';
import {
  DynamicLinkActions,
  GenerateDynamicLinkReqProps,
  GenerateDynamicLinkResProps
} from 'services/general/general.types';

import { selectSignUp, selectUser } from 'store';
import { clearUserState } from 'store/signup/signupSlice';
import { setUser } from 'store/user/userSlice';

import WelcomeToUnlimited from 'modals/WelcomeToUnlimited';
import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import ButtonAddToCalendar from 'shared/ButtonAddToCalendar';
import VerificationBanner from 'widgets/VerificationBanner';

import { useAppDispatch, useAppSelector } from 'hooks';
import useContinueInWeb from 'hooks/useContinueInWeb';
import useWidth from 'hooks/useWidth';
import { DateFormat, FlowTypes, PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { ConfirmationProps } from './confirmation.types';

// import success from 'assets/animations/success.json';

// const options = {
//   animationData: success,
//   loop: false
// };

const Confirmation: React.FC<ConfirmationProps> = ({
  appointment: { startTime, endTime, title, id, description, isStartsSoon, apptName = '' },
  isPopupOpen,
  closePopup,
  providerName = '',
  providerId,
  src,
  showActionRequiredBanner = true
}) => {
  // const { View } = useLottie(options);
  const { isMobile } = useWidth();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [generateDynamicLink, { isLoading }] = useGenerateDynamicLinkMutation();
  const [getToken, { isLoading: isGettingToken }] = useGetShortTermTokenMutation();

  const { user, firstAppointment } = useAppSelector(selectSignUp);
  const { accessToken } = useAppSelector(selectUser);
  const isSignUpPage = location.pathname.includes(PathName.SignUp);
  const isB1LikeFlow = location.pathname.includes(FlowTypes.BasicFlow);

  const continueInWeb = useContinueInWeb(user);

  const clearUserData = () => {
    dispatch(
      setUser({
        isEmailVerified: false,
        profileImage: '',
        userId: user._id
      })
    );
    dispatch(clearUserState());
    sessionStorage.removeItem('session-id');
  };

  const handleGenerateDynamicLinkThen = ({ data }: GenerateDynamicLinkResProps) => {
    setTimeout(() => window.open(data.dynamicLink, '_top'));
  };

  const handleGenerateDynamicLink = async (action = DynamicLinkActions.DASHBOARD) => {
    try {
      const tokenData = await getToken(isSignUpPage ? user.accessToken : accessToken).unwrap();
      const body: GenerateDynamicLinkReqProps = {
        accessToken: isSignUpPage ? user.accessToken : accessToken,
        action,
        src: src === 'appMobile' ? src : 'signUp',
        ...(isSignUpPage && {
          appointmentId: firstAppointment._id,
          doctorId: providerId,
          expiredToken: tokenData.data.token
        }),
        ...(action === 'waiting-room' && {
          appointmentId: id,
          doctorId: providerId
        })
      };
      await generateDynamicLink(body).unwrap().then(handleGenerateDynamicLinkThen);
    } catch (e) {
      handleRequestCatch(e as MessageEvent, 'Something went wrong, please try again later');
    } finally {
      if (isSignUpPage) clearUserData();
    }
  };

  const onClickEnterWaitingRoom = () => {
    if (src === 'appMobile') {
      handleGenerateDynamicLink(DynamicLinkActions.WAITING_ROOM);
    } else {
      isSignUpPage
        ? continueInWeb(id)
        : navigate({
            pathname: PathName.Call,
            search: `callId=${id}`
          });
    }
  };

  const onClickBackToTheDashboard = () => {
    src === 'appMobile'
      ? handleGenerateDynamicLink(DynamicLinkActions.DASHBOARD)
      : navigate(PathName.Dashboard);
  };

  const fillUserWithRedirect = () => {
    isMobile ? handleGenerateDynamicLink(DynamicLinkActions.DASHBOARD) : continueInWeb();
  };

  return (
    <>
      {closePopup && <WelcomeToUnlimited isOpen={!!isPopupOpen} onClose={closePopup} />}
      <SlideAnimateWrapper className="flex h-full grow flex-col items-center gap-4 md:gap-8">
        <h1
          className="text-center text-mXl font-bold text-primary md:text-2xl"
          data-testid="appointment_scheduled_heading"
        >
          {title}
        </h1>
        {showActionRequiredBanner && <VerificationBanner />}
        {/* <div className="mx-auto w-[178px] md:w-[196px]">{View}</div> */}
        <Common.Illustration name="thumbs-up" />
        <div className="flex flex-col items-center gap-4">
          <h2 className="text-mLg font-bold md:text-xl" data-testid="appointment_planned_date">
            {dayjs(startTime).format(DateFormat.dddd_MMMM_D_h_mm_A)}
          </h2>
          <h3 className="hidden text-mBase font-bold text-primary md:text-sm">{providerName}</h3>
          <p
            className="break-word line-clamp-4 text-center max-md:min-h-[50px] md:line-clamp-2 md:max-w-md md:text-base"
            data-testid="appointment_description"
          >
            {description}
          </p>
          {!isStartsSoon && (
            <div className="w-full md:w-auto">
              <ButtonAddToCalendar
                dataTestId="add_to_calendar_btn"
                endsAt={endTime}
                id={id}
                name={apptName ? `Your LifeMD appointment re: ${apptName}` : title}
                startsAt={startTime}
              />
            </div>
          )}
        </div>
        <div className="flex justify-end gap-3 max-md:h-full max-md:flex-col md:justify-center md:gap-4">
          <Common.Button
            color="blue"
            dataTestId="take_to_dashboard_btn"
            isLoading={isGettingToken || isLoading}
            postIcon="arrow-right"
            fullWidthOnMobile
            onClick={() => {
              isSignUpPage ? fillUserWithRedirect() : onClickBackToTheDashboard();
            }}
          >
            Take me to my dashboard
          </Common.Button>
          {(!isSignUpPage || isB1LikeFlow) && (
            <Common.Button
              color={isStartsSoon ? 'blue' : 'white-alt'}
              dataTestId="enter_waiting_room_btn"
              isLoading={isLoading || isGettingToken}
              fullWidthOnMobile
              onClick={onClickEnterWaitingRoom}
            >
              {isStartsSoon ? 'Join appointment' : 'Enter waiting room'}
            </Common.Button>
          )}
        </div>
      </SlideAnimateWrapper>
    </>
  );
};

export default Confirmation;
