import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { WITHINGS_BODY_PRO_2_SCALE_PRICE } from 'constants/defaults';

import ScaleSvg from 'assets/images/wm/scale.svg?react';

interface WMScalesProps {
  isAddedToCart: boolean;
  onClickAdd: () => void;
  onClickLearnMore: () => void;
  priceWithDiscount?: string | null;
}

const WMScales: React.FC<WMScalesProps> = ({
  onClickAdd,
  onClickLearnMore,
  isAddedToCart,
  priceWithDiscount = ''
}) => {
  const parsePriceWithDiscount = priceWithDiscount === '0' ? 'Free' : `$${priceWithDiscount}`;
  const noPriceWithDiscount = priceWithDiscount === null;

  return (
    <div className="flex flex-col flex-wrap gap-7 max-md:px-4 max-md:py-6 md:items-center">
      <div className="flex w-full justify-between gap-7 md:gap-12">
        <div>
          <p className="mb-2 text-mXl font-bold text-primary-900 md:text-xl">
            Withings Body Pro 2 Scale
          </p>
          <p className="text-mLg font-semibold md:mb-6">
            {!noPriceWithDiscount && (
              <span className="mr-1 text-primary-800">{parsePriceWithDiscount}</span>
            )}
            <span
              className={classNames('text-gray', { 'line-through': !noPriceWithDiscount })}
            >{` $${WITHINGS_BODY_PRO_2_SCALE_PRICE} `}</span>
          </p>
          <div className="flex flex-col gap-1 max-md:hidden">
            <p className="text-lg font-bold text-primary-600">Stay motivated with live feedback</p>
            <p className="text-mSm text-primary-400 md:text-base">
              Track progress and stay motivated with the <b>Withings Body Pro 2 Scale</b>, designed
              for seamless integration with your LifeMD program.
            </p>
          </div>
        </div>
        <ScaleSvg className="scaly-y-100 size-24 flex-none -scale-x-100 md:w-[120px]" />
      </div>
      <div className="md:hidden">
        <p className="font-bold text-primary-600">Stay motivated with live feedback</p>
        <p className="mt-1 text-mSm text-primary-400">
          Track progress and stay motivated with the <b>Withings Body Pro 2 Scale</b>, designed for
          seamless integration with your LifeMD program.
        </p>
      </div>
      <div className="flex gap-3 self-start">
        <Common.Button
          className={classNames('text-white', isAddedToCart ? 'bg-green' : 'bg-black')}
          preIcon={isAddedToCart ? 'check' : undefined}
          size="sm"
          onClick={onClickAdd}
        >
          {isAddedToCart ? 'Added' : 'Add to order'}
        </Common.Button>
        <Common.Button size="sm" onClick={onClickLearnMore}>
          Learn more
        </Common.Button>
      </div>
    </div>
  );
};

export default WMScales;
