import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { SUPPORT_PHONE_NUMBER } from 'utils/constants';
import { FlowTypes } from 'utils/enums';

import { Props } from './headerNew.types';

const HeaderNew: React.FC<Props> = ({ clickOnPrev, isShowBackButton = true, flow }) => {
  const logoClassName = 'size-7 md:h-8 md:w-8';

  return (
    <header className="flex items-center justify-between py-6 md:px-10 md:pb-0 md:pt-2">
      <button
        className={classNames('flex items-center', {
          invisible: !isShowBackButton
        })}
        data-testid="arrow_back"
        onClick={clickOnPrev}
      >
        <Common.Icon className="size-5 text-gray" name="arrow-left" />
      </button>

      <div className="flex items-center gap-4">
        <Common.Logo className={logoClassName} name="cross" />
        {flow === FlowTypes.WeightManagementFlowOptavia && (
          <>
            <Common.Icon name="close" />
            <Common.Logo className={logoClassName} name="optavia" />
          </>
        )}
      </div>
      <a href={SUPPORT_PHONE_NUMBER.link}>
        <Common.Icon className="size-7 text-primary-700 md:size-6" name="phone" />
      </a>
    </header>
  );
};

export default HeaderNew;
