import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import { useLazyGetHealthMetricsQuery } from 'services/healthMetrics/healthMetrics';
import { SortField, SortOrder } from 'services/healthMetrics/healthMetrics.types';

import { selectLatestHealthMetrics, selectUser } from 'store';

import Pagination from 'features/Pagination';
import Table from 'features/Table';
import BloodPressureEdit from 'modals/BloodPressureEdit';
import Loader from 'shared/Loader';
import ButtonAddHealthMetrics from 'widgets/myChart/HealthMetrics/ButtonAddHealthMetrics';
import Collapse from 'widgets/myChart/HealthMetrics/Collapse';

import { useAppSelector } from 'hooks';
import useWeightManagement from 'hooks/useWeightManagement';
import useWidth from 'hooks/useWidth';
import { DateFormat, PathName } from 'utils/enums';

import { BloodPressureProps, TableItem } from './bloodPressure.types';

const BloodPressure: React.FC<BloodPressureProps> = ({ onUpdate }) => {
  const [getHealthMetrics, { isLoading, data }] = useLazyGetHealthMetricsQuery();
  const [tableData, setTableData] = useState<TableItem[]>([]);
  const [elementForEdit, setElementForEdit] = useState<TableItem | undefined>();
  const [params, setParams] = useState<{
    limit: number;
    pageNo: number;
    sortField: SortField;
    sortOrder: SortOrder;
  }>({
    pageNo: 0,
    limit: 5,
    sortField: 'collectedDate',
    sortOrder: 'DESC'
  });
  const [isOpenModal, toggleIsOpenModal] = useToggle(false);
  const [isOpenCollapse, toggleIsOpenCollapse] = useToggle(false);
  const { isMobile } = useWidth();
  const navigate = useNavigate();

  const { bloodPressure } = useAppSelector(selectLatestHealthMetrics);
  const { isWMDevices } = useAppSelector(selectUser);
  const { isRWEPatient } = useWeightManagement();

  const handleDisplayEditModal = (element?: TableItem) => {
    if (isRWEPatient) {
      return navigate({
        pathname: PathName.AddMeasurement,
        search: 'measurement=bloodPressure'
      });
    }
    setElementForEdit(element);
    toggleIsOpenModal();
  };

  const handleGetHealthMetrics = () => {
    getHealthMetrics({ ...params, metricType: 'bloodPressure' });
  };

  const handleCloseModal = (status?: boolean | unknown) => {
    toggleIsOpenModal();
    if (status) {
      handleGetHealthMetrics();
      onUpdate();
    }
  };

  useEffect(() => {
    if (!data?.data) return;

    const mappedData = data.data.map(({ collectedDate, metrics: { bloodPressure }, source }) => ({
      'metrics.bloodPressure.DIA': `${bloodPressure?.DIA || 0}`,
      'metrics.bloodPressure.SYS': `${bloodPressure?.SYS || 0}`,
      collectedDate: dayjs(collectedDate.split('T')[0]).format(DateFormat.MMM_DD_YYYY),
      'metrics.bloodPressure.pulse': `${bloodPressure?.pulse || 0}${isMobile ? '' : ' bpm'}`,
      method: source ? 'Device' : 'Manual'
    }));

    setTableData(mappedData);
  }, [data]);

  useEffect(() => {
    isOpenCollapse && handleGetHealthMetrics();
  }, [params, isOpenCollapse]);

  const totalCount = data?.info?.totalCount || 0;

  return (
    <>
      <Loader isVisible={isLoading} />
      <BloodPressureEdit element={elementForEdit} isOpen={isOpenModal} onClose={handleCloseModal} />
      {bloodPressure ? (
        <Collapse
          actions={(cb, isOpen) => (
            <div className="mt-4 flex justify-end gap-3">
              {(!isMobile || (isMobile && !isOpen)) && (
                <Common.Button color="white" fullWidthOnMobile onClick={cb}>
                  {isOpen ? 'Show less' : isMobile ? 'View more' : 'Show more'}
                </Common.Button>
              )}
              {(!isMobile || (isMobile && isOpen)) && (
                <Common.Button
                  color="white-alt"
                  preIcon="plus"
                  fullWidthOnMobile
                  onClick={() => handleDisplayEditModal()}
                >
                  Add measurement
                </Common.Button>
              )}
            </div>
          )}
          alwaysOpenChildren={
            <div className="mt-6 grid grid-cols-3 gap-2 text-center">
              <div className="rounded-2xl bg-gray-100 p-3">
                <p className="text-sm text-gray">Systolic</p>
                <p className="mt-1 text-xl font-bold">{bloodPressure.SYS}</p>
              </div>
              <div className="rounded-2xl bg-gray-100 p-3">
                <p className="text-sm text-gray">Diastolic</p>
                <p className="mt-1 text-xl font-bold">{bloodPressure.DIA}</p>
              </div>
              <div className="rounded-2xl bg-gray-100 p-3">
                <p className="text-sm text-gray">Pulse</p>
                <p className="mt-1 text-xl font-bold">{bloodPressure.pulse}</p>
              </div>
            </div>
          }
          dataTestId="blood_pressure_displayed"
          subTitle={`${bloodPressure.SYS}/${bloodPressure.DIA}`}
          title="Blood Pressure"
          onChange={toggleIsOpenCollapse}
        >
          <div className="my-6">
            <Table
              boldCols={[]}
              data={tableData}
              dataTestId="blood_pressure_measurement_row"
              disableAction={{ key: 'method', value: 'Scale' }}
              handleSort={({ sortField, sortOrder }) =>
                setParams({ ...params, sortField: sortField as SortField, sortOrder })
              }
              headers={[
                { id: 'collectedDate', label: 'Date' },
                { id: 'metrics.bloodPressure.SYS', label: 'SYS' },
                { id: 'metrics.bloodPressure.DIA', label: 'DIA' },
                { id: 'metrics.bloodPressure.pulse', label: 'pulse' },
                ...(isWMDevices ? [{ id: 'method' }] : [])
              ]}
              tableClassName="md:rounded-2xl md:border"
              tableRowClassName="md:border-none"
              onClickRow={
                isRWEPatient ? undefined : (el) => handleDisplayEditModal(el as TableItem)
              }
            />
            {totalCount > params.limit && (
              <Pagination
                labelDirection="column"
                params={{ ...params, totalCount }}
                onChange={({ selected }) => setParams({ ...params, pageNo: selected })}
              />
            )}
          </div>
        </Collapse>
      ) : (
        <ButtonAddHealthMetrics
          text="Add Blood Pressure"
          onClick={() => handleDisplayEditModal()}
        />
      )}
    </>
  );
};

export default BloodPressure;
