import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import { RoughPeriodDate, WomenHealthPick, WomenHealthProps } from 'store/mif/mif.types';

import ListItemWrapper from 'shared/animationWrappers/ListItemWrapper';
import CalendarInput from 'shared/form/CalendarInput';

import { PATIENTS_AGE_LIMITS } from 'utils/constants';
import { DateFormat } from 'utils/enums';

import { Option } from 'models/forms.types';

import { WomensHealthPropsComponent } from './womensHealth.types';

export const PERIOD_OPTIONS: Option<RoughPeriodDate>[] = [
  { label: '1-2 weeks', value: '0' },
  { label: '3-5 weeks', value: '1' },
  { label: '6-9 weeks', value: '2' },
  { label: '2-3 months', value: '3' },
  { label: '4 months or more', value: '4' }
];

const WomensHealth: React.FC<WomensHealthPropsComponent> = ({
  name,
  onUpdate,
  startDate,
  approxStartDate = '',
  loading = false
}) => {
  const [isUnsure, setIsUnsure] = useState(false);

  const handleUpdate = <T extends keyof WomenHealthProps>(
    value: T extends 'name'
      ? WomenHealthPick
      : T extends 'roughPeriodDate'
        ? RoughPeriodDate
        : string | null,
    type: T
  ) => {
    const newData: WomenHealthProps = {
      approxStartDate,
      name,
      startDate,
      [type]: value as WomenHealthProps[T]
    };

    if (type === 'startDate') {
      newData.approxStartDate = '';
    }

    onUpdate(newData);
  };

  const handleChangeUnsure = () => {
    const newStartDate = isUnsure ? dayjs().format(DateFormat.MM_DD_YYYY) : '';
    handleUpdate(newStartDate, 'startDate');
    setIsUnsure((prevState) => !prevState);
  };

  const parsedDate = (date: Date | string | null) =>
    date ? dayjs(date).format(DateFormat.MM_DD_YYYY) : null;

  const onChangeDate = (val: Date) => {
    if (parsedDate(val) !== parsedDate(startDate)) {
      handleUpdate(parsedDate(val), 'startDate');
    }
  };

  useEffect(() => {
    setIsUnsure(!startDate);
  }, [approxStartDate, startDate]);

  return (
    <ListItemWrapper dataTestId="women_health">
      <div className="flex flex-col gap-2">
        <Common.RichRadioButton
          checked={name === '0'}
          disabled={loading}
          label="I&#8217;m post-menopausal"
          value="1"
          onChange={() => handleUpdate('0', 'name')}
        />
        <Common.RichRadioButton
          checked={name === '1'}
          disabled={loading}
          label="I&#8217;m currently pregnant"
          value="2"
          onChange={() => handleUpdate('1', 'name')}
        />
        <Common.RichRadioButton
          checked={name === '2'}
          className="!overflow-visible"
          disabled={loading}
          label="Neither of the above"
          value="3"
          onChange={() => handleUpdate('2', 'name')}
        >
          {name === '2' && (
            <>
              <div className="mb-4 flex flex-col items-start gap-3 md:flex-row md:items-center">
                <CalendarInput
                  disabled={[
                    {
                      after: new Date(),
                      before: dayjs().subtract(PATIENTS_AGE_LIMITS.MAX, 'years').toDate()
                    }
                  ]}
                  disabledInput={isUnsure || loading}
                  endMonth={new Date()}
                  label="When did your last period start?"
                  placeholder="Select date"
                  selected={startDate ? dayjs(startDate).toDate() : new Date()}
                  startMonth={dayjs().subtract(PATIENTS_AGE_LIMITS.MAX, 'years').toDate()}
                  onDateChange={onChangeDate}
                />
                <div className="min-w-fit self-start md:mt-7 md:self-auto">
                  <Common.Checkbox
                    checked={isUnsure}
                    disabled={loading}
                    onChange={handleChangeUnsure}
                  >
                    I&#8217;m unsure
                  </Common.Checkbox>
                </div>
              </div>
              {isUnsure && (
                <div className="max-w-[268px]">
                  <Common.SelectAlt
                    disabled={loading}
                    label="If you can, please estimate approx number of weeks or months since it started."
                    options={PERIOD_OPTIONS}
                    placeholder="Weeks/months ago..."
                    value={approxStartDate}
                    hideSuccessState
                    onChange={(v) => handleUpdate(v, 'approxStartDate')}
                  />
                </div>
              )}
            </>
          )}
        </Common.RichRadioButton>
      </div>
    </ListItemWrapper>
  );
};

export default WomensHealth;
