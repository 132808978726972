import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { Common } from '@thecvlb/design-system';

import { useLazyGetMyAccountQuery } from 'services/myAccount/myAccount';

import { selectSignUp } from 'store';

import { WmFlowType } from 'containers/SignUp/Content/content.types';
import Loader from 'shared/Loader';

import { WEIGHT_MANAGEMENT_VIDEO_URL } from 'constants/externalLinks';
import { useAppSelector, useQuery } from 'hooks';
import useContinueInWeb from 'hooks/useContinueInWeb';
import useWidth from 'hooks/useWidth';
import { ASPECT_RATIO } from 'utils/constants';
import { PathName } from 'utils/enums';

import FinalStepsAccordion from '../parts/FinalStepsAccordion';
import Heading from '../parts/Heading';

import Sign from 'assets/images/signUp/b-sehgal.svg';

const QUESTIONS_LIST = [
  {
    content: (
      <div className="text-mXs md:text-xs">
        <p className="mb-1 font-semibold">
          Most patients will need to submit their address and insurance information (including their
          insurance card) to complete their onboarding.
        </p>
        <p className="text-white/70">
          You will not be allowed to enter your scheduled appointment unless you have completed your
          pre-appointment onboarding. The pre-appointment onboarding is vital to speeding up the
          time it will take to start the first dose of GLP-1 medication. The information you provide
          will allow us to check if your insurance covers GLP-1 medication and may allow you to
          receive brand name GLP-1 medication at a discounted price.
        </p>
      </div>
    ),
    id: '1',
    title: 'Complete your onboarding'
  },
  {
    content: (
      <div className="text-mXs md:text-xs">
        <p className="mb-1 font-semibold">
          Once your pre-appointment onboarding is completed, attend your first appointment with your
          LifeMD-affiliated provider. You can access your appointment on your computer or smartphone
          using the LifeMD app.
        </p>
        <p className="text-white/70">
          On this call, your assigned provider will go over your health information, insurance
          coverage, and weight loss goals to come up with a Weight Management program that’s right
          for you. If appropriate, they’ll also prescribe you GLP-1 medication, which you will need
          to either pick up at the pharmacy or wait for it to get delivered.
        </p>
      </div>
    ),
    id: '2',
    title: 'Attend your first appointment'
  },
  {
    content: (
      <div className="text-mXs md:text-xs">
        <p className="mb-1 font-semibold">
          After you receive your GLP-1 medication, follow the instructions and take your first dose!
          This will mark the formal start of your weight loss journey.
        </p>
        <p className="text-white/70">
          Make sure to let us know when you start your first dose, GLP-1 medications are typically
          dosed weekly, so by letting us know, we’ll be able to send you reminders. Also be sure to
          check out the helpful resources, such as guides and videos in your Weight Management
          patient portal. Medical professionals are also available in the messaging room in your
          patient portal, they are there to answer any medical question you may have.
        </p>
      </div>
    ),
    id: '3',
    title: 'Take your first dose'
  }
];

const FinishOnboarding: React.FC<{ flow?: WmFlowType }> = ({ flow = 'onboarding' }) => {
  const [getMyAccount, { isFetching }] = useLazyGetMyAccountQuery();
  const query = useQuery();
  const navigate = useNavigate();
  const { isMobile } = useWidth();
  const { user } = useAppSelector(selectSignUp);
  const { width } = useWindowSize();
  const videoWidth = isMobile ? '100%' : 500;
  const continueInWeb = useContinueInWeb(user);

  const handleComplete = () => {
    if (flow === 'authorized') {
      getMyAccount()
        .unwrap()
        .finally(() => navigate(PathName.Dashboard));
    } else {
      const isErrorState = query.get('verification-failed');
      continueInWeb(
        '',
        isErrorState ? PathName.VerifyProfile : PathName.IntakeForm,
        `?redirectTo=${PathName.IntakeForm}${isErrorState ? '&verification-failed=1' : ''}`
      );
    }
  };

  return (
    <>
      <Loader isVisible={isFetching} />
      <div className="flex flex-col gap-6 pb-6 pt-4 md:pb-16 md:pt-8">
        <Heading
          category="Sign up"
          subtitle={
            <p className="text-mSm text-primary-700">
              Please check your email for details to access the LifeMD portal. Be sure to check your
              spam as well.
            </p>
          }
          title="Welcome to LifeMD!"
        />
        {WEIGHT_MANAGEMENT_VIDEO_URL ? (
          <iframe
            allow="autoplay; controls; fullscreen; gyroscope; encrypted-media; accelerometer; picture-in-picture"
            aria-controls="video_popup"
            className="m-auto"
            data-testid="video_popup"
            src={WEIGHT_MANAGEMENT_VIDEO_URL}
            style={{
              height: isMobile ? width / ASPECT_RATIO : 320,
              width: videoWidth
            }}
            title="video"
            allowFullScreen
          />
        ) : (
          <span className="block px-12 text-center">
            Oops, welcome video is missing, we`re working on it.
          </span>
        )}
        <Common.Button
          className="self-center"
          color="blue"
          fullWidthOnMobile
          onClick={handleComplete}
        >
          Complete onboarding
        </Common.Button>
      </div>
      <div className="fluid flex flex-col place-items-center items-center bg-secondary-100 px-4 py-6 text-center text-mSm text-secondary-800 md:py-12 md:text-sm">
        <h4 className="mb-4">
          “Welcome! You’ve made a great choice. Before we can write your GLP prescriptions, you must
          complete your onboarding... it’s quick and easy.”
        </h4>
        <img alt="sign" src={Sign} />
        <span>Dr. Banita Sehgal</span>
        <span>Board Certified Internal Medicine</span>
      </div>
      <div className="fluid flex flex-col place-items-center items-center gap-4 bg-primary px-4 py-6 text-white md:gap-6 md:py-16">
        <h2 className="text-2xl font-bold">Next Steps:</h2>
        <div className="mx-auto w-full max-w-[500px]">
          <FinalStepsAccordion questions={QUESTIONS_LIST} />
        </div>
        <Common.Button
          className="self-center"
          color="white"
          fullWidthOnMobile
          onClick={handleComplete}
        >
          Complete onboarding
        </Common.Button>
      </div>
    </>
  );
};

export default FinishOnboarding;
