import { selectUser } from 'store';

import { PlanCodes, WMDeviceFlow } from 'utils/enums';
import { checkIfUserIsTTUser, getLatestSurvey } from 'utils/helpers';

import { useAppSelector } from './index';

const useWeightManagement = () => {
  const {
    activePlanCode,
    isAsyncSignup,
    hadOrHasWeightManagement,
    weightManagement,
    activePricePoint,
    wmDeviceFlow
  } = useAppSelector(selectUser);
  const incompleteSurvey =
    getLatestSurvey(weightManagement?.incompleteSurveys, 'renewal') ||
    getLatestSurvey(weightManagement?.incompleteSurveys, 'check-in');

  return {
    hadOrHasWeightManagement,
    isAsyncPlan: isAsyncSignup,
    isWeightManagement: activePlanCode === PlanCodes.WeightManagementMembership,
    incompleteSurvey,
    isTTPatient: checkIfUserIsTTUser(activePricePoint),
    isRWEPatient: wmDeviceFlow === WMDeviceFlow.WMDevices
  };
};

export default useWeightManagement;
