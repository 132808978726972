import { Common } from '@thecvlb/design-system';

import { Props } from './checkboxGroup.types';

const CheckboxGroup: React.FC<Props<string | number>> = ({
  isLoading,
  items,
  selectedItems,
  onSelect,
  onTextAreaChange,
  textAreaValue
}) => {
  return (
    <div className="flex flex-col gap-2">
      {items.map((item, i) => (
        <>
          <Common.RichCheckbox
            checked={selectedItems.includes(item.value)}
            disabled={isLoading}
            key={typeof item.label === 'string' ? item.label + i : String(item.value) + i}
            label={item.label}
            name={String(item.value)}
            value={item.value}
            onChange={() => onSelect(item.value)}
          >
            {selectedItems.includes(item.value) && !!item.textArea && (
              <Common.TextArea
                dataTestId="text_area"
                disabled={isLoading}
                name={String(item.value)}
                placeholder={item.textArea.placeholder || ''}
                value={textAreaValue?.find((i) => i.option === item.value)?.value || ''}
                onChange={(v) => onTextAreaChange?.(String(v.target.value), String(item.value))}
              />
            )}
          </Common.RichCheckbox>
        </>
      ))}
    </div>
  );
};

export default CheckboxGroup;
