import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { useFlag } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';

import {
  useLazyGetChartHealthMetricsQuery,
  useLazyGetHealthMetricsQuery
} from 'services/healthMetrics/healthMetrics';
import { SortField, SortOrder } from 'services/healthMetrics/healthMetrics.types';

import { selectLatestHealthMetrics, selectUser } from 'store';

import Pagination from 'features/Pagination';
import Table from 'features/Table';
import WeightChart from 'features/WeightChart';
import MyDevice from 'modals/MyDevice';
import WeightEdit from 'modals/WeightEdit';
import Loader from 'shared/Loader';
import ButtonAddHealthMetrics from 'widgets/myChart/HealthMetrics/ButtonAddHealthMetrics';
import Collapse from 'widgets/myChart/HealthMetrics/Collapse';

import { useAppSelector } from 'hooks';
import useWeightManagement from 'hooks/useWeightManagement';
import useWidth from 'hooks/useWidth';
import { DateFormat, FeatureFlag, PathName } from 'utils/enums';

import { TableItem, WeightProps } from './weight.types';

const Weight: React.FC<WeightProps> = ({ onUpdate }) => {
  const [getChartHealthMetrics, { data: chartData }] = useLazyGetChartHealthMetricsQuery();
  const [getHealthMetrics, { isLoading, data }] = useLazyGetHealthMetricsQuery();
  const [tableData, setTableData] = useState<TableItem[]>([]);
  const [elementForEdit, setElementForEdit] = useState<TableItem | undefined>();
  const [params, setParams] = useState<{
    limit: number;
    pageNo: number;
    sortField: SortField;
    sortOrder: SortOrder;
  }>({
    pageNo: 0,
    limit: 5,
    sortField: 'collectedDate',
    sortOrder: 'DESC'
  });
  const [isOpenModal, toggleIsOpenModal] = useToggle(false);
  const [isOpenCollapse, toggleIsOpenCollapse] = useToggle(false);
  const [isOpenMyDevice, toggleIsOpenMyDevice] = useToggle(false);
  const { isMobile } = useWidth();
  const isWeightChartDetailsFeature = useFlag(FeatureFlag.WeightChartDetails);
  const navigate = useNavigate();

  const { weight, bodyFat, boneMass, muscleMass, totalWater, visceralFat, details } =
    useAppSelector(selectLatestHealthMetrics);
  const { isWMDevices } = useAppSelector(selectUser);
  const { isRWEPatient } = useWeightManagement();

  const handleDisplayEditModal = (element?: TableItem) => {
    if (isRWEPatient) {
      return navigate({
        pathname: PathName.AddMeasurement,
        search: 'measurement=weight'
      });
    }
    setElementForEdit(element);
    toggleIsOpenModal();
  };

  const handleGetHealthMetrics = () => {
    getHealthMetrics({ ...params, metricType: 'weight' });
  };

  const handleCloseModal = (status?: boolean | unknown) => {
    toggleIsOpenModal();
    if (status) {
      handleGetHealthMetrics();
      getChartHealthMetrics({ metricType: 'weight' });
      onUpdate();
    }
  };

  const formatMetrics = (suffix: string, metrics?: number) =>
    `${metrics || 'n/a'} ${metrics ? suffix : ''}`;

  useEffect(() => {
    if (data?.data) {
      const mappedData = data.data.map(({ metrics, collectedDate, source }) => ({
        collectedDate: dayjs(collectedDate.split('T')[0]).format(DateFormat.MMM_DD_YYYY),
        'metrics.weight': formatMetrics('lbs', metrics.weight),
        'metrics.bodyFat': formatMetrics('%', metrics.bodyFat),
        'metrics.boneMass': formatMetrics('lbs', metrics.boneMass),
        'metrics.muscleMass': formatMetrics('lbs', metrics.muscleMass),
        'metrics.totalWater': formatMetrics('%', metrics.totalWater),
        'metrics.visceralFat': formatMetrics('lbs', metrics.visceralFat),
        method: source ? 'Scale' : 'Manual'
      }));
      setTableData(mappedData);
    }
  }, [data]);

  useEffect(() => {
    getChartHealthMetrics({ metricType: 'weight' });
  }, []);

  useEffect(() => {
    isOpenCollapse && handleGetHealthMetrics();
  }, [params, isOpenCollapse]);

  const firstWeight = chartData?.data?.current[chartData?.data.current?.length - 2]?.value || 0;
  const lastWeight = chartData?.data?.current[chartData?.data.current?.length - 1];
  const totalCount = data?.info?.totalCount || 0;

  const weightDeltaPercentage = firstWeight
    ? Math.round(((firstWeight - (lastWeight?.value || 0)) / firstWeight) * 100)
    : 0;

  const lastWeightDays = dayjs().diff(lastWeight?.date.split('T')[0], 'day');

  return (
    <>
      <Loader isVisible={isLoading} />
      <WeightEdit
        currentWeight={chartData?.data?.current[chartData?.data.current?.length - 1]?.value}
        element={elementForEdit}
        isOpen={isOpenModal}
        onClose={handleCloseModal}
      />
      <MyDevice deviceName={details?.name} isOpen={isOpenMyDevice} onClose={toggleIsOpenMyDevice} />
      {typeof weight === 'number' ? (
        <Collapse
          actions={(cb, isOpen) => (
            <div className="mt-4 flex justify-end gap-3">
              {(!isMobile || (isMobile && !isOpen)) && (
                <Common.Button color="white" fullWidthOnMobile onClick={cb}>
                  {isOpen ? 'Show less' : isMobile ? 'View more' : 'Show more'}
                </Common.Button>
              )}
              {(!isMobile || (isMobile && isOpen)) && (
                <Common.Button
                  color="white-alt"
                  preIcon="plus"
                  fullWidthOnMobile
                  onClick={() => handleDisplayEditModal()}
                >
                  Add measurement
                </Common.Button>
              )}
            </div>
          )}
          alwaysOpenChildren={
            chartData?.data && (
              <>
                {isWeightChartDetailsFeature && (
                  <>
                    <div className="mt-2 flex flex-col gap-3">
                      <h3 className="font-bold">Weight</h3>
                      <div className="flex gap-4">
                        <Common.ColorTag
                          color="green"
                          text={`-${chartData?.data.weightManagement?.weightLost} lbs`}
                        />
                        <p className="text-gray">
                          Last renewal:{' '}
                          <span className="text-gray-700">
                            {chartData?.data.weightManagement?.weightPrevious} lbs
                          </span>
                        </p>
                        <p className="text-gray">
                          Current:{' '}
                          <span className="text-gray-700">
                            {chartData?.data.weightManagement?.weightCurrent} lbs
                          </span>
                        </p>
                      </div>
                    </div>
                    {!!chartData?.data.weightManagement?.sideEffects.length && (
                      <div className="mt-2 flex flex-col gap-3">
                        <h3 className="font-bold">Side effects</h3>
                        <div className="flex gap-4">
                          {chartData?.data.weightManagement?.sideEffects.map((el) => (
                            <Common.ColorTag color="green" key={el.id} text={el.severity} />
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
                <WeightChart
                  current={chartData.data.current}
                  target={chartData.data.target}
                  hideTitle
                />
                {visceralFat && (
                  <button
                    className="mx-auto mt-2 flex items-center justify-center gap-1 p-2 text-mXs text-gray"
                    onClick={toggleIsOpenMyDevice}
                  >
                    <Common.Icon className="size-3" name="link" />
                    Smart Scale • Updated{' '}
                    {dayjs(lastWeight?.date).format(DateFormat.MMM_D_YYYY_h_mma_z)}
                  </button>
                )}
                {isWMDevices && (
                  <div className="mt-4 grid grid-cols-2 gap-2 text-center">
                    <div className="rounded-2xl bg-gray-100 p-3">
                      <p className="text-sm text-gray">Visceral fat</p>
                      <p className="mt-1 text-xl font-bold">
                        {visceralFat ? `${visceralFat} lbs` : 'n/a'}
                      </p>
                    </div>
                    <div className="rounded-2xl bg-gray-100 p-3">
                      <p className="text-sm text-gray">Bone mass</p>
                      <p className="mt-1 text-xl font-bold">
                        {boneMass ? `${boneMass} lbs` : 'n/a'}
                      </p>
                    </div>
                    <div className="rounded-2xl bg-gray-100 p-3">
                      <p className="text-sm text-gray">Muscle mass</p>
                      <p className="mt-1 text-xl font-bold">
                        {muscleMass ? `${muscleMass} lbs` : 'n/a'}
                      </p>
                    </div>
                    <div className="rounded-2xl bg-gray-100 p-3">
                      <p className="text-sm text-gray">Body fat %</p>
                      <p className="mt-1 text-xl font-bold">{bodyFat ? `${bodyFat} %` : 'n/a'}</p>
                    </div>
                    <div className="rounded-2xl bg-gray-100 p-3">
                      <p className="text-sm text-gray">Total water %</p>
                      <p className="mt-1 text-xl font-bold">
                        {totalWater ? `${totalWater} %` : 'n/a'}
                      </p>
                    </div>
                    <div className="rounded-2xl bg-gray-100 p-3">
                      <p className="text-sm text-gray">Days ago measured</p>
                      <p className="mt-1 text-xl font-bold">
                        {lastWeightDays ? `${lastWeightDays} days` : 'Today'}
                      </p>
                    </div>
                  </div>
                )}
                {!visceralFat && (
                  <p className="mt-2 flex items-center justify-center gap-1 p-2 text-mXs text-gray">
                    <Common.Icon className="size-3" name="patient-outline" />
                    Manually added • Updated{' '}
                    {dayjs(lastWeight?.date).format(DateFormat.MMM_D_YYYY_h_mma_z)}
                  </p>
                )}
              </>
            )
          }
          colorTag={
            <Common.ColorTag
              color={weightDeltaPercentage < 0 ? 'red' : 'green'}
              icon={weightDeltaPercentage < 0 ? 'arrow-up' : 'arrow-down'}
              text={`${Math.abs(weightDeltaPercentage)}%`}
            />
          }
          dataTestId="weight_displayed"
          subTitle={`${weight} lbs`}
          title="Weight"
          onChange={toggleIsOpenCollapse}
        >
          <div className="my-6 flex flex-col items-center">
            <Table
              boldCols={[]}
              data={tableData}
              dataTestId="weight_measurement_row"
              disableAction={{ key: 'method', value: 'Scale' }}
              handleSort={({ sortField, sortOrder }) =>
                setParams({ ...params, sortField: sortField as SortField, sortOrder })
              }
              headers={[
                {
                  id: 'collectedDate',
                  label: 'date'
                },
                {
                  id: 'metrics.weight',
                  label: 'weight'
                },
                ...(isWMDevices
                  ? [
                      {
                        id: 'metrics.visceralFat',
                        label: 'visceral fat'
                      },
                      {
                        id: 'metrics.boneMass',
                        label: 'bone mass'
                      },
                      {
                        id: 'metrics.muscleMass',
                        label: 'muscle mass'
                      },
                      {
                        id: 'metrics.bodyFat',
                        label: 'body fat'
                      },
                      {
                        id: 'metrics.totalWater',
                        label: 'total water'
                      },
                      {
                        id: 'method',
                        label: 'method'
                      }
                    ]
                  : [])
              ]}
              tableClassName="md:rounded-2xl md:border w-full"
              tableRowClassName="md:border-none"
              onClickRow={
                isRWEPatient ? undefined : (el) => handleDisplayEditModal(el as TableItem)
              }
            />
            {totalCount > params.limit && (
              <Pagination
                labelDirection="column"
                params={{ ...params, totalCount }}
                onChange={({ selected }) => setParams({ ...params, pageNo: selected })}
              />
            )}
          </div>
        </Collapse>
      ) : (
        <ButtonAddHealthMetrics text="Add Weight" onClick={() => handleDisplayEditModal()} />
      )}
    </>
  );
};

export default Weight;
