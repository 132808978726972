import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { useLazyGetAvailableDatesQuery as useLazyGetGlobalAvailableDatesQuery } from 'services/auth/auth';
import { useLazyGetAvailableDatesQuery } from 'services/providers/providers';
import { GetAvailableDatesDataProps } from 'services/providers/providers.types';

import { notifyError } from 'shared/Toast/Toast';

import { DateFormat, FlowTypes } from 'utils/enums';
import { findDisabledDates } from 'utils/helpers';

import { ProviderType } from 'models/appointment.types';

export const useGetInitialDatesWithSlots = ({
  doctorID,
  appointmentTypeId,
  accessToken,
  planId,
  state,
  isReschedule,
  excludeMyProvider = true,
  flow,
  providers = ['my', 'any'],
  isWeightManagementAppointment,
  planPricePointId,
  initialDate,
  callbackOnError
}: {
  accessToken?: string;
  appointmentTypeId?: string;
  callbackOnError?: (error: Error) => void;
  doctorID?: string;
  excludeMyProvider?: boolean;
  flow?: FlowTypes | 'authorized';
  initialDate?: string;
  isReschedule?: boolean;
  isWeightManagementAppointment?: boolean;
  planId: string;
  planPricePointId?: string;
  providers?: ProviderType[];
  state: string;
}): {
  anyProvidersDisabledDates: string[];
  anyProvidersFreeDates: string[];
  loading: boolean;
  myProviderDisabledDates: string[];
  myProviderFreeDates: string[];
} => {
  const timezone = dayjs.tz.guess();
  let startDate = dayjs(initialDate || undefined).format(DateFormat.YYYY_MM_DD);
  if (!dayjs(initialDate).isValid()) {
    startDate = dayjs().format(DateFormat.YYYY_MM_DD);
  }

  const [
    getAvailableDatesForProvider,
    { isLoading: isInitialLoadingGettingDates, isFetching: isFetchingProvider }
  ] = useLazyGetAvailableDatesQuery();
  const [getAvailableDatesGlobal, { isLoading, isFetching }] =
    useLazyGetGlobalAvailableDatesQuery();

  const [myProviderFreeDates, setMyProviderFreeDates] = useState<string[]>([]);
  const [myProviderDisabledDates, setMyProviderDisabledDates] = useState<string[]>([]);
  const [anyProvidersDisabledDates, setAnyProvidersDisabledDates] = useState<string[]>([]);
  const [anyProvidersFreeDates, setAnyProvidersFreeDates] = useState<string[]>([]);

  const splitDates = (
    res: GetAvailableDatesDataProps[] = [],
    periodStart: string,
    periodEnd: string
  ) => {
    const freeDates = res.map((date) => date.date);
    const disabledDates = findDisabledDates(
      res.map((d) => d.date),
      periodStart,
      periodEnd
    );
    return { disabledDates, freeDates };
  };

  const handleCatch = (e: Error) => {
    if ('status' in e && e.status === 401) {
      callbackOnError?.(e);
    } else {
      notifyError((e as Error)?.message);
    }
  };

  const getDates = () => {
    try {
      if (providers.includes('my') && !appointmentTypeId && !isWeightManagementAppointment) {
        throw new Error('appointmentTypeId is required');
      }
      doctorID &&
        providers.includes('my') &&
        getAvailableDatesForProvider(
          {
            appointmentTypeId,
            doctorId: doctorID,
            endDate: dayjs(startDate).add(30, 'days').format(DateFormat.YYYY_MM_DD),
            isWeightManagementAppointment,
            startDate: dayjs(startDate).format(DateFormat.YYYY_MM_DD),
            timezone,
            ...(accessToken && { accessToken }),
            ...(!!planPricePointId && { planPricePointId })
          },
          true
        )
          .unwrap()
          .then((res) => {
            // logic here is following: if there is more than 4 free dates, we will show them, otherwise we will make additional request for the next month
            const { freeDates, disabledDates } = splitDates(
              res.data,
              dayjs(startDate).format(DateFormat.YYYY_MM_DD),
              dayjs(startDate).add(30, 'days').format(DateFormat.YYYY_MM_DD)
            );
            const firstDate = freeDates[0];
            const isFirstFreeDateAtCurrentMonth = dayjs(firstDate).isSameOrBefore(
              dayjs(startDate).endOf('month')
            );
            if (freeDates.length > 4 && isFirstFreeDateAtCurrentMonth) {
              setMyProviderFreeDates(freeDates);
              setMyProviderDisabledDates(disabledDates);
            } else {
              getAvailableDatesForProvider(
                {
                  appointmentTypeId,
                  doctorId: doctorID,
                  endDate: dayjs(startDate).add(60, 'days').format(DateFormat.YYYY_MM_DD),
                  isWeightManagementAppointment,
                  startDate: dayjs(startDate).add(30, 'days').format(DateFormat.YYYY_MM_DD),
                  timezone,
                  ...(accessToken && { accessToken }),
                  ...(!!planPricePointId && { planPricePointId })
                },
                true
              )
                .unwrap()
                .then((res2) => {
                  const {
                    freeDates: freeDatesFromSecondAttempt,
                    disabledDates: disabledDatesFromSecondAttempt
                  } = splitDates(
                    res2.data,
                    dayjs(startDate).add(30, 'days').format(DateFormat.YYYY_MM_DD),
                    dayjs(startDate).add(60, 'days').format(DateFormat.YYYY_MM_DD)
                  );
                  setMyProviderFreeDates([
                    ...new Set([...freeDates, ...freeDatesFromSecondAttempt])
                  ]);
                  setMyProviderDisabledDates([...disabledDates, ...disabledDatesFromSecondAttempt]);
                })
                .catch((e) => {
                  setMyProviderFreeDates(freeDates);
                  setMyProviderDisabledDates(disabledDates);
                  handleCatch(e);
                });
            }
          })
          .catch(handleCatch);
      appointmentTypeId &&
        state &&
        providers.includes('any') &&
        getAvailableDatesGlobal(
          {
            ...(accessToken && { accessToken }),
            ...(!!planPricePointId && { planPricePointId }),
            excludeMyProvider,
            appointmentTypeId,
            endDate: dayjs(startDate).add(30, 'days').format(DateFormat.YYYY_MM_DD),
            isReschedule,
            planId,
            startDate: dayjs(startDate).format(DateFormat.YYYY_MM_DD),
            state,
            timezone,
            ...(flow === FlowTypes.WeightManagementFlowOptavia && {
              onboardingPartnerName: 'Optavia'
            })
          },
          true
        )
          .unwrap()
          .then((res) => {
            const { freeDates, disabledDates } = splitDates(
              res.data,
              dayjs(startDate).format(DateFormat.YYYY_MM_DD),
              dayjs(startDate).add(30, 'days').format(DateFormat.YYYY_MM_DD)
            );
            const firstDate = freeDates[0];
            const isFirstFreeDateAtCurrentMonth = dayjs(firstDate).isSameOrBefore(
              dayjs(startDate).endOf('month')
            );
            if (freeDates.length > 4 && isFirstFreeDateAtCurrentMonth) {
              setAnyProvidersFreeDates(freeDates);
              setAnyProvidersDisabledDates(disabledDates);
            } else {
              getAvailableDatesGlobal(
                {
                  ...(accessToken && { accessToken }),
                  ...(!!planPricePointId && { planPricePointId }),
                  appointmentTypeId,
                  endDate: dayjs(initialDate).add(60, 'days').format(DateFormat.YYYY_MM_DD),
                  isReschedule,
                  excludeMyProvider,
                  planId,
                  startDate: dayjs(initialDate).add(30, 'days').format(DateFormat.YYYY_MM_DD),
                  state,
                  timezone,
                  ...(flow === FlowTypes.WeightManagementFlowOptavia && {
                    onboardingPartnerName: 'Optavia'
                  })
                },
                true
              )
                .unwrap()
                .then((res2) => {
                  const {
                    freeDates: freeDatesFromSecondAttempt,
                    disabledDates: disabledDatesFromSecondAttempt
                  } = splitDates(
                    res2.data,
                    dayjs(initialDate).add(30, 'days').format(DateFormat.YYYY_MM_DD),
                    dayjs(initialDate).add(60, 'days').format(DateFormat.YYYY_MM_DD)
                  );
                  setAnyProvidersFreeDates([
                    ...new Set([...freeDates, ...freeDatesFromSecondAttempt])
                  ]);
                  setAnyProvidersDisabledDates([
                    ...disabledDates,
                    ...disabledDatesFromSecondAttempt
                  ]);
                })
                .catch((e) => {
                  setAnyProvidersFreeDates(freeDates);
                  setAnyProvidersDisabledDates(disabledDates);
                  handleCatch(e);
                });
            }
          })
          .catch(handleCatch);
    } catch (e) {
      notifyError((e as Error)?.message);
    }
  };

  useEffect(getDates, [appointmentTypeId, state]);

  return {
    anyProvidersDisabledDates,
    anyProvidersFreeDates,
    loading: isInitialLoadingGettingDates || isLoading || isFetching || isFetchingProvider,
    myProviderDisabledDates,
    myProviderFreeDates
  };
};
