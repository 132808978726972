import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { OrchestrateState } from 'store/orchestrate-signup/orchestrate.types';

import { PaymentCardDataProps } from '../../Checkout/checkout.types';

interface PaymentSummaryCardProps {
  additionalPurchaseInfo?: {
    color?: string;
    header: string;
    price: string;
    subheader?: string;
  };
  data: PaymentCardDataProps;
  insurance?: OrchestrateState['insurances'][0];
}

const PaymentSummaryCard: React.FC<PaymentSummaryCardProps> = ({
  data: { ppTitle, ppPrice, ppSubheader, discountPercentage, discountAmount },
  insurance,
  additionalPurchaseInfo
}) => {
  const isPercentageDiscount =
    !!insurance?.eligibilityResponse &&
    typeof insurance.eligibilityResponse?.coinsurance === 'number' &&
    insurance.eligibilityResponse.coinsurance > 0;
  const finalCost = insurance?.eligibilityResponse?.finalCost;
  const fixedFinalCost = finalCost
    ? `${(finalCost / 100).toFixed((finalCost / 100) % 1 !== 0 ? 2 : 0)}`
    : '';

  return (
    <>
      {!!insurance?.eligibilityResponse &&
        ['ACTIVE', 'ACTIVE_AT_RISK'].includes(insurance.eligibilityResponse.status) && (
          <div className="mb-6 flex justify-between gap-2 rounded-2xl border border-gray-200 bg-gray-100 p-4 md:mb-8">
            <div className="flex flex-col gap-2">
              <p className="text-sm font-bold">Your insurance has been accepted</p>
              <div className="flex gap-1">
                <Common.Icon name="check-shield" />
                <div>
                  <p className="text-sm">
                    Your total {isPercentageDiscount ? 'coinsurance' : 'copay'} is ${fixedFinalCost}{' '}
                    for this telehealth appointment.
                  </p>
                  <p className="text-sm text-gray">
                    {isPercentageDiscount ? 'Coinsurance' : 'Copay'} does not include cost of
                    medication.
                  </p>
                </div>
              </div>
            </div>
            <Common.Icon className="size-[30px] text-green" name="check-circle" />
          </div>
        )}
      <div className="flex flex-col gap-4 rounded-2xl border border-secondary-200 bg-secondary-50 p-6">
        {!!finalCost && (
          <div>
            <div className="flex justify-between font-bold text-primary-700 md:text-lg">
              <h3>{isPercentageDiscount ? 'Coinsurance' : 'Copay'}</h3>
              <span>${fixedFinalCost}</span>
            </div>
            {!!insurance?.provider && (
              <span className="block text-left text-sm text-primary">{insurance.provider}</span>
            )}
          </div>
        )}
        <div>
          <div
            className={classNames(
              'flex justify-between font-bold md:text-lg',
              !!insurance?.eligibilityResponse ? 'text-secondary' : 'text-primary-700'
            )}
          >
            <h3>{ppTitle}</h3>
            <span>${ppPrice}</span>
          </div>
          <span
            className={classNames(
              'block text-left text-sm',
              !!insurance?.eligibilityResponse ? 'text-secondary' : 'text-primary'
            )}
          >
            {ppSubheader}
          </span>
        </div>
        {!discountPercentage && !!discountAmount && (
          <div className="flex justify-between font-bold text-orange md:text-lg">
            <div className="flex items-center gap-2 font-bold md:flex-col md:items-start">
              <h3>
                ${Number(discountAmount).toFixed(Number(discountAmount) % 1 !== 0 ? 2 : 0)} off your
                first month
              </h3>
            </div>
            <span>
              - ${Number(discountAmount).toFixed(Number(discountAmount) % 1 !== 0 ? 2 : 0)}
            </span>
          </div>
        )}
        {!!discountPercentage && (
          <div className="flex justify-between font-bold text-orange md:text-lg">
            <div className="flex items-center gap-2 font-bold md:flex-col md:items-start">
              <h3>Discount: {discountPercentage} off</h3>
            </div>
            <span>- ${discountAmount}</span>
          </div>
        )}
        {!!additionalPurchaseInfo && (
          <div className={additionalPurchaseInfo.color}>
            <div className="flex justify-between font-bold md:text-lg">
              <h3>{additionalPurchaseInfo.header}</h3>
              <span>${additionalPurchaseInfo.price}</span>
            </div>
            {!!additionalPurchaseInfo.subheader && (
              <span className="block text-left text-sm">{additionalPurchaseInfo.subheader}</span>
            )}
          </div>
        )}
        <div className="h-px w-full bg-secondary" />

        <div className="flex justify-between font-bold text-primary-700 md:text-lg">
          <h3>Total due now</h3>
          <span>
            $
            {Number(ppPrice) -
              Number(discountAmount) +
              Number((finalCost || 0) / 100) +
              Number(additionalPurchaseInfo?.price ?? 0)}
          </span>
        </div>
        {!!insurance && (
          <p className="text-mSm md:text-sm">
            You’ll see two transactions on your credit card statement. One for the Copay, and one
            for the Membership.
          </p>
        )}
      </div>
    </>
  );
};

export default PaymentSummaryCard;
